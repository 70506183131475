<template>
    <div>
      <b-container fluid>
        <b-row>
          <b-col cols="12" md="12" lg="12">
            <b-card header-tag="header" header-bg-variant="dark">
              <template #header>
                <h5 class="mb-0" style="color: #fff">
                  <strong>Add Detail Stock Opname {{ nama_gudang }}</strong>
                </h5>
              </template>

              <b-row>
                <b-col cols="12" md="12" lg="12">
                  <b-button variant="primary" v-b-modal.modal-input-add-item
                    ><CIcon name="cil-plus" /> Tambah Data</b-button
                  >
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" md="12" lg="12" class="mt-4">
                  <b-table
                    :items="items"
                    :fields="fields"
                    responsive
                    show-empty
                    bordered
                    striped
                    hover
                    :busy="tableBusy"
                  >
                    <template #cell(jumlah_stock_real)="item">
                        <b-form-input
                            type="number"
                            v-model="item.item.jumlah_stock_real"
                            @input="getSelisih(item.item)"
                        ></b-form-input>
                    </template>
                    <template #cell(selisih_stock)="item">
                        <b-form-input
                            type="number"
                            v-model="item.item.selisih_stock"
                            disabled
                        ></b-form-input>
                    </template>
                    <template #cell(keterangan_sub_stock_opname)="item">
                        <b-form-input
                            type="text"
                            v-model="item.item.keterangan_sub_stock_opname"
                        ></b-form-input>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
              <b-row>
                <b-col offset="11">
                    <b-button
                        variant="primary"
                        @click="simpan()"
                        >Simpan</b-button
                    >
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-container>

      <b-modal
        id="modal-input-add-item"
        size="lg"
        centered
        title="Tambah Item Stock Opname"
        header-bg-variant="primary"
        header-text-variant="light"
      >
        <b-form>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Barang <span class="text-danger">*</span>
            </template>
            <multiselect
                :options="list_barang"
                v-model="is_data.barang_id"
                :multiple="false"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                label="nama_barang"
                track-by="ms_barang_id"
                placeholder="--Pilih Barang--"
                @input="getBatch(is_data.barang_id)"
            ></multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Batch <span class="text-danger">*</span>
            </template>
            <multiselect
                :options="list_batch"
                v-model="is_data.kode_batch"
                :multiple="false"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                label="kode_batch"
                track-by="kode_batch"
                placeholder="--Pilih Batch--"
            ></multiselect>
          </b-form-group>
        </b-form>
        <!-- <img :src="src1" /> -->
        <template #modal-footer>
          <b-button variant="secondary" @click="$bvModal.hide('modal-input-add-item')">
            Batal
          </b-button>
          <b-button
            variant="primary"
            :disabled="disabled"
            @click="addItem()"
            >Add Item</b-button
          >
        </template>
      </b-modal>
    </div>
  </template>
  
  <script>
  import _ from "lodash"
  import moment from "moment"
  export default {
    name: "list_pembelian",

    data() {
      return {
        showing: false,
        variant: "success",
        list_barang: [],
        list_batch: [],
        is_data: {
            barang_id: "",
            kode_batch: ""
        },
        msg: "",
        fields: [
          {
            key: "no",
            label: "#",
            sortDirection: "desc",
            sortable: true,
            class: "table-number text-center",
          },
  
          {
            key: "nama_barang",
            label: "Nama Barang",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },

          {
            key: "kode_batch",
            label: "Kode Batch",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },

          {
            key: "tgl_kadaluarsa",
            label: "Tgl Kadaluarsa",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },

          {
            key: "jumlah_stock_data",
            label: "Stock",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },
          {
            key: "jumlah_stock_real",
            label: "Stock Real",
            sortable: true,
            sortDirection: "desc",
            class: "text-left col-1",
          },
          {
            key: "selisih_stock",
            label: "Selisih",
            sortable: true,
            sortDirection: "desc",
            class: "text-left col-2",
          },
          {
            key: "keterangan_sub_stock_opname",
            label: "Keterangan",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },
        ],
        items: [],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        filter: null,
        filterOn: [],
        tableBusy: false,
      };
    },
    computed: {
        id_opname() {
            return this.$route.query ? this.$route.query.id_opname : null;
        },
        id_gudang() {
            return this.$route.query ? this.$route.query.id_gudang : null;
        },
        nama_gudang() {
            return this.$route.query ? this.$route.query.nama_gudang : null;
        },
        disabled(){
            return this.is_data.barang_id && this.is_data.kode_batch ? false : true
        }
    },
    async mounted() {
      // Set the initial number of items
      this.getDatas();
    },
    watch: {
        // perPage: function (newVal, oldVal) {
        //     if (newVal != oldVal) {
        //         this.getDatas();
        //     }
        // },
        // currentPage: function (newVal, oldVal) {
        //     if (newVal != oldVal) {
        //         this.getDatas();
        //     }
        // },
    },
    methods: {
        getSelisih(x){
            let vm = this
            x.selisih_stock = x.jumlah_stock_real - parseInt(x.jumlah_stock_data)
            console.log(vm.items);
        },
        async getBatch(x){
            let vm = this
            console.log(x);
            let batch = await vm.$axios.post("/stock/list_batch_by_gudang", {
                ms_barang_id: x.ms_barang_id,
                ms_gudang_id: vm.id_gudang,
            });
            if(batch.data.data.length){
                vm.list_batch = batch.data.data
            }else {
                vm.$store.commit("set_alert", {variant: "danger", msg: "TIDAK ADA DATA", showing: true});
            }
            console.log(batch, 'ini batch');
        },
        addItem(){
            let vm = this
            let item = {}
            item.no = vm.items.length + 1
            item.nama_barang = vm.is_data.barang_id.nama_barang
            item.ms_barang_id = vm.is_data.barang_id.ms_barang_id
            item.kode_batch = vm.is_data.kode_batch.kode_batch
            item.jumlah_stock_data = vm.is_data.kode_batch.qty_stock
            item.jumlah_stock_real = 0
            item.keterangan_sub_stock_opname = ""
            item.stock_opname_id = vm.id_opname
            item.tgl_kadaluarsa = vm.$moment(vm.is_data.kode_batch.tanggal_kadaluarsa).format("YYYY-MM-DD")
            // console.log(item);
            vm.items.push(item)
            vm.$bvModal.hide("modal-input-add-item");
        },
      async getDatas() {
        let vm = this
        try {
          this.tableBusy = true;
          let ms_barang = await vm.$axios.post("/ms_barang/list", {
            halaman: 0,
            jumlah: 1000,
            });
            console.log(ms_barang, 'ini barang');
            vm.list_barang = ms_barang.data.data
          let list_stock = await vm.$axios.post("/stock_opname/list_barang_opname", {
            id : vm.id_opname,
            ms_gudang_id: vm.id_gudang
          });
          let x = list_stock.data;
          console.log(list_stock, 'ini ress')
          if (x.status === 200 && x.message === "sukses") {
            vm.items = list_stock.data.data;
            vm.items = list_stock.data.data.map((item, idx) => {
                item.no = idx + 1;
                if(!item.jumlah_stock_real){
                    item.jumlah_stock_real = 0
                }
                item.selisih_stock = item.jumlah_stock_real - item.jumlah_stock_data
                item.tgl_kadaluarsa = vm.$moment(item.tanggal_kadaluarsa).format("YYYY-MM-DD")
                return item;
            });
            console.log(vm.items, 'ini items')
            this.totalRows = vm.items.length;
          } else {
            throw x;
          }
        } catch (err) {
          if (err.message) {
            vm.$store.commit("set_alert", {variant: "danger", msg: _.toUpper(err.message), showing: true});
          } else {
            vm.$store.commit("set_alert", {variant: "danger", msg: _.toUpper(err), showing: true});
          }
        } finally {
          this.tableBusy = false;
        }
      },
      simpan(){
        let vm = this
        // console.log(vm.items);
        let bulk = []
        for (let i = 0; i < vm.items.length; i++) {
            let x = vm.items[i];
            let itemnya = {}
            itemnya.jumlah_stock_data = x.jumlah_stock_data
            itemnya.jumlah_stock_real = x.jumlah_stock_real
            itemnya.keterangan_sub_stock_opname = x.keterangan_sub_stock_opname
            itemnya.kode_batch = x.kode_batch
            itemnya.stock_opname_id = vm.id_opname
            itemnya.sub_stock_opname_id = x.sub_stock_opname_id
            itemnya.ms_barang_id = x.ms_barang_id
            bulk.push(itemnya)
        }
        let tgl = moment(new Date).format("YYYY-MM-DD HH:mm")
        vm.$axios
          .post("/stock_opname/submit_stock_opname", {
            id: vm.id_opname,
            bulk_barang_opname: bulk,
            ms_gudang_id: vm.id_gudang,
            tanggal_submit: tgl
          })
          .then((res) => {
            console.log(res, 'ini respon cuii');
            if (res.data.message == "sukses") {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$store.commit("set_alert", {
                variant: "success",
                msg: "BERHASIL MEMASUKAN DATA STOCK OPNAME",
                showing: true,
              });
              this.$router.push({
                path: '/gudang/stock_opname',
              });
            } else {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$store.committ("set_alert", {
                variant: "danger",
                msg: _.toUpper(res.data.message),
                showing: true,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            vm.button = "Simpan";
            vm.busy = false;
            vm.$store.commit("set_alert", {
              variant: "danger",
              msg: "TERJADI KESALAHAN PADA SERVER",
              showing: true,
            });
          });
        console.log(bulk);
      },
      onChildClicked(action, data) {
        // console.log(data, 'ini datanyaaaa');
            this.$router.push({
                path: action,
                query: { id : data.item.item.pembelian_id },
            });
        },

        rowClass(item, type) {
            if (!item || type !== 'row') return
            console.log(item.kadaluarsanya, 'itikiw');
            if (item.kadaluarsa3bln == true){
              return 'table-danger'
            }else if(item.kadaluarsa6bln == true){
              return 'table-warning'
            }
        },
      triggerAlert(event) {
        let vm = this;
        // console.log('ihiyyyyyyyyyyyy');
        vm.$store.commit("set_alert", event);
        vm.getDatas()
      },
    },
  };
  </script>